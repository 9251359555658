import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const JobOverview = () => {
  const pathname =
    typeof window !== "undefined"
      ? window.location.pathname.substring(6).replace("/", "")
      : "";

  const data = useStaticQuery(graphql`
    {
      jobs: allFile(
        filter: { sourceInstanceName: { eq: "jobs" }, extension: { eq: "mdx" } }
        sort: { order: DESC, fields: childMdx___frontmatter___order }
      ) {
        nodes {
          childMdx {
            frontmatter {
              title
            }
          }
          id
          name
        }
      }
    }
  `);

  return (
    <>
      {!!data.jobs?.nodes &&
        data.jobs.nodes.filter((node) => node.name !== pathname).length > 0 && (
          <>
            <h3>Job Übersicht</h3>
            {data.jobs.nodes
              .filter((node) => node.name !== pathname)
              .map((node) => (
                <div key={node.id} style={{ marginBottom: 16 }}>
                  <Link to={`/jobs/${node.name}`} style={{ color: "black" }}>
                    {node.childMdx.frontmatter.title}&nbsp;&gt;
                  </Link>
                </div>
              ))}
          </>
        )}
    </>
  );
};

export default JobOverview;
